import React, { Component } from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

import './scss/style.scss'
import './css/map.css'
import { getSession } from './views/utils/useSessionStorage'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./Login'))
class App extends Component {
  render() {
    const auth = JSON.parse(getSession('isAuthenticated'))

    return (
      <Router>
        <React.Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<Login />} />
            <Route path="*" name="Home" element={auth ? <DefaultLayout /> : <Navigate to="/" />} />
          </Routes>
        </React.Suspense>
      </Router>
    )
  }
}

export default App
